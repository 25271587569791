<template>
  <v-list-item
    v-bind="$attrs"
    v-on="{ ...$listeners }"
    color="primary"
    :value="value"
  >
    <v-list-item-avatar v-if="avatar">
      <v-icon> {{ avatar }} </v-icon>
    </v-list-item-avatar>
    <v-list-item-icon v-if="icon">
      <v-icon> {{ icon }} </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ value ? value.title : defaultTitle }}
      </v-list-item-title>
      <v-list-item-subtitle>{{
        value ? formatDatespan(value.startDate, value.endDate) : '–'
      }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="action"
      ><v-icon>{{ action }}</v-icon></v-list-item-action
    >
    <v-list-item-action-text v-if="actionText">{{
      actionText
    }}</v-list-item-action-text>
  </v-list-item>
</template>
<script>
import { formatDatespan } from 'common/utils/date.js';

export default {
  name: 'Course',
  props: {
    action: null,
    actionText: null,
    defaultTitle: {
      type: String,
      value: '-',
    },
    value: null,
    avatar: null,
    icon: null,
  },
  methods: {
    formatDatespan: formatDatespan,
  },
};
</script>
